import React from "react";
import { EntomoLogo, AllProducts } from "../images";
import Container from "../utils/Container";

const AllProductsIcon = () => {
  return (
    <div>
      <div className="flex items-center justify-around mb-12 flex-wrap flex-col md:flex-row ">
        <div>
          <img className="md:W-[210px] w-[120px] xl:w-[210px]" src={EntomoLogo} alt="logoimagae" />
        </div>
        <div className="flex items-center justify-around mr-8 pt-4 text-lg">
          <p>
            Purpose<span className="px-4">|</span>
          </p>
          <p>
            Insights<span className="px-4">|</span>
          </p>
          <p>Growth</p>
        </div>
      </div>
      <Container>
        <div className="mb-4 flex flex-col items-center">
          <div className=" text-xl mb-6 text-[#0064bf] text-center font-medium xl:w-8/12 lg:text-xl xl:text-xl xl:font-extrabold">
            entomo drives enterprise performance by aligning goals and generating insights by connecting various data
            sources. The insights and action recommendations generated are hypersonalized to each user’s goals
          </div>
          <img className="xl:w-8/12" src={AllProducts} alt="iconimage" />
        </div>
      </Container>
    </div>
  );
};

export default AllProductsIcon;
