import React from "react";
import PropTypes from "prop-types";

import PromiseEntity from "../components/PromiseEntity";

import { homePage, productPage } from "../constants/entomo-promises-variants";

const PromiseList = ({ promiseList, variant = homePage, title }) => {
  return (
    <section className="bg-primary-blue-dark overflow-clip">
      <div>{title}</div>
      {promiseList.map((promise) => (
        <PromiseEntity key={promise.title} {...promise} variant={variant}></PromiseEntity>
      ))}
    </section>
  );
};

PromiseList.propTypes = {
  variant: PropTypes.oneOf([homePage, productPage]),
  promiseList: PropTypes.arrayOf({
    mainImage: PropTypes.string.isRequired,
    subImage: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    reversed: PropTypes.bool,
    listItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  title: PropTypes.string,
};

PromiseList.defaultProps = {
  variant: homePage,
};

export default PromiseList;
