import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import LookingForMoreInfo from "../../../containers/LookingForMoreInfo";
import PromiseList from "../../../containers/PromiseList";
import AllProductService from "../../../components/AllProductService";
import AllProductsIcon from "../../../components/AllProductsIcon";
import Container from "../../../utils/Container";
import BreadCrumb from "../../../containers/BreadCrumb";
const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "entomo",
    link: "/",
  },
  {
    text: " platform",
    link: "/",
  },
];

const Entomo = ({ data }) => {
  const lookingForMoreInfo = data.lookingForMoreInfo.nodes[0];
  const allProducts = data.allProducts.nodes[0];
  const bannerData = data.bannerData.nodes[0];
  return (
    <Layout>
      <Seo title="Home" />
      <Banner bgImg={bannerData.desktopImage.gatsbyImageData} mobImg={bannerData.mobileImage.gatsbyImageData}>
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 lg:top-60 2xl:left-28 md:top-48 md:left-12 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold md:mb-3 md:font-medium xl:font-bold sm:text-normal xl:text-5xl md:text-3xl xl:mb-6 lg:mb-6 2xl:text-6xl">
                <strong>{bannerData.title}</strong>
              </h2>
            }
            subTitle={
              <p className=" md:-mt-4 text-[#000000] text-3xl lg:text-4xl xl:text-[2.7rem] xl:w-full font-extrabold md:text-3xl 2xl:text-6xl">
                {bannerData.subTitle}
              </p>
            }
          />
        </div>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 1rem" }} />
      </Container>
      <AllProductsIcon />
      <PromiseList
        variant={1}
        promiseList={[
          {
            mainImage: allProducts.promiseImgOne.gatsbyImageData,
            title: allProducts.titleone,
            desc: allProducts.description,
            reversed: true,
            listItems: [allProducts.text1, allProducts.text2, allProducts.text3],
          },
          {
            mainImage: allProducts.promiseMobImgTwo.gatsbyImageData,
            title: allProducts.title2,
            desc: allProducts.description2,
            reversed: false,
            listItems: [allProducts.text4, allProducts.text5, allProducts.text6],
          },
          {
            mainImage: allProducts.promiseMobImgThree.gatsbyImageData,
            title: allProducts.title3,
            desc: allProducts.description3,
            reversed: true,
            listItems: [allProducts.text7, allProducts.text8, allProducts.text9],
          },
          {
            mainImage: allProducts.promiseImgFour.gatsbyImageData,
            title: allProducts.title4,
            desc: allProducts.description4,
            reversed: false,
            listItems: [allProducts.text10, allProducts.text11, allProducts.text12, allProducts.text13],
          },
          {
            mainImage: allProducts.promiseImgFive.gatsbyImageData,
            title: allProducts.title5,
            desc: allProducts.description5,
            reversed: true,
            listItems: [allProducts.text14, allProducts.text15, allProducts.text17],
          },
        ]}
      />
      <AllProductService />
      <LookingForMoreInfo
        centerImg={lookingForMoreInfo.centerImg.gatsbyImageData}
        heading={lookingForMoreInfo.heading}
        leftSideText={lookingForMoreInfo.leftSideText}
        rightSideText={lookingForMoreInfo.rightSideText}
      />
    </Layout>
  );
};
Entomo.propTypes = {
  data: PropTypes.object,
};

export default Entomo;
export const query = graphql`
  query {
    bannerData: allContentfulBannerContent(filter: { heading: { eq: "All Products Page Banner" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
        title
        subTitle
      }
    }
    allProducts: allContentfulPromisesList(filter: { heading: { eq: "AllProducts PromiseList" } }) {
      nodes {
        promiseImgOne {
          gatsbyImageData(formats: WEBP)
        }
        promiseMobImgTwo {
          gatsbyImageData(formats: WEBP)
        }
        promiseMobImgThree {
          gatsbyImageData(formats: WEBP)
        }
        promiseImgFour {
          gatsbyImageData(formats: WEBP)
        }
        promiseImgFive {
          gatsbyImageData(formats: WEBP)
        }
        description
        description2
        description3
        description4
        description5
        titleone
        title2
        title3
        title4
        title5
        text1
        text2
        text3
        text4
        text5
        text6
        text7
        text8
        text9
        text10
        text11
        text12
        text13
        text14
        text15
        text17
      }
    }
    lookingForMoreInfo: allContentfulLookingForMoreInfo {
      nodes {
        centerImg {
          gatsbyImageData(formats: WEBP)
        }
        heading
        leftSideText
        rightSideText
      }
    }
  }
`;
