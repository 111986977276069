import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { homePage, productPage } from "../constants/entomo-promises-variants";

const PromiseEntity = ({
  mainImage,
  title,
  desc,
  className,
  style,
  listItems,
  subImage = "",
  reversed = false,
  variant = homePage,
}) => {
  const isHomepage = variant === homePage;

  return (
    <div
      className={`${
        reversed ? "lg:flex-row-reverse" : "lg:flex-row"
      } flex flex-col  md:justify-center 2xl:justify-between md:flex-row 2xl:mx-48  py-4 items-center ${className} ${
        reversed ? "2xl:justify-center" : "2xl:justify-between"
      } `}
      style={style}
    >
      {isHomepage ? null : (
        <h3
          className={`font-extrabold text-3xl text-primary-yellow-dark self-start pl-5 block md:hidden
        }`}
        >
          {title}
        </h3>
      )}
      <GatsbyImage
        image={mainImage}
        alt="cover image"
        className={`${reversed ? "md:-mr-0" : "md:-ml-0"} max-w-full md:max-w-[50%] mb-11 md:mb-0`}
      />
      <div
        className={` ${reversed ? "2xl:ml-28" : "lg:mr-12"} px-5 flex flex-col gap-3 w-full md:w-1/2 ${
          reversed ? "lg:ml-20" : "lg:mr-12"
        }`}
      >
        <div>
          <div className="flex md:flex-col-reverse flex-row md:gap-3 self-stretch justify-between border-b-2 border-[#CCCCCC] w-11/12 md:w-11/12 xl:w-8/12">
            <div className={`flex ${isHomepage ? "flex-col gap-2" : "flex-col-reverse gap-4"} `}>
              <p
                className={`text-white text-lg ${
                  isHomepage
                    ? "font-extrabold md:font-bold md:text-2xl lg:text-3xl"
                    : "font-bold md:text-sm lg:text-2xl"
                }`}
              >
                {desc}
              </p>
              <h3
                className={`font-extrabold text-4xl lg:text-5xl xl:text-[56px] text-primary-yellow-dark  mb-5 ${
                  isHomepage ? "block" : "hidden md:block"
                }`}
              >
                {title}
              </h3>
            </div>
            {isHomepage ? (
              <img className="max-w-[60px] md:max-w-[74px] lg:max-w-[89px] lg:mb-4 pb-4" src={subImage} alt="symbol" />
            ) : null}
          </div>
          {isHomepage ? <div className="hidden h-[2px] mt-4 lg:mt-7 mx-auto bg-[#CCCCCC]"></div> : null}
        </div>
        <ul
          className={`flex flex-col text-white text-xs font-bold mt-4 gap-2 md:text-xs xl:text-base lg:gap-5 ml-5 list-['⦿'] ${
            isHomepage ? "marker:text-primary-yellow-dark" : "marker:text-white"
          }`}
        >
          {listItems.map((listItem) => (
            <li className="pl-2" key={listItem}>
              {listItem}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PromiseEntity.propTypes = {
  mainImage: PropTypes.string.isRequired,
  subImage: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf([homePage, productPage]),
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  reversed: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PromiseEntity.defaultProps = {
  subImage: "",
  className: "",
  style: {},
  reversed: false,
  variant: homePage,
};

export default PromiseEntity;
