import React from "react";

const AllProductService = () => {
  return (
    <div className="bg-[#f2f2f2] text-[#0064bf] font-extrabold text-center leading-4 py-6 xl:py-0">
      <div className="m-auto  w-11/12 xl:w-11/12 md:w-full flex items-center flex-col sm:flex-row justify-between">
        <div className=" px-4 py-8 text-lg xl:text-sm xl:w-3/12">
          <div className="pb-2 lg:text-sm ">multilingual support</div>
          <div className="text-[#808080] text-base lg:text-sm">
            English, Chinese, Korean, Japanese, Bahasa, Thai, and Arabic
          </div>
        </div>
        <div className="px-4 py-8 text-lg xl:text-sm lg:text-sm xl:w-3/12">
          <div>ISO 27001, SOC 2 certified</div>
        </div>
        <div className="px-4 py-8 text-lg xl:text-sm lg:text-sm xl:w-3/12">
          <div>onboarding, onsite rollout support</div>
        </div>

        <div className="px-4 py-8 text-lg xl:text-sm lg:text-sm xl:w-3/12">
          <div>SSO/SAML supported</div>
        </div>
      </div>
    </div>
  );
};
export default AllProductService;
